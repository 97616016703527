import React from 'react';
import { Line, Chart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(ChartDataLabels);
const Daily = require('../../../../assets/data/Daily.json');

export const LineChartDays = React.memo(props => {
  const maxValue = Math.max.apply(
    null,
    Daily.index.map(item => Object.values(item)[0])
  );
  const arrWithIfo = Daily.index.filter(item => item.info !== '');
  const arrWithInfoDates = arrWithIfo.map(i => Object.keys(i)[0]);
  const developmentsValues = [];
  const developmentsDates = [];
  Daily.index.map(item => {
    arrWithInfoDates.map(i => {
      Object.keys(item)[0] === i && developmentsValues.push(item[i]) && developmentsDates.push(Object.keys(item)[0]);
      return i;
    });

    return item;
  });
  const chartData = {
    labels: Daily.index.map(item => Object.keys(item)[0]),
    datasets: [
      {
        label: 'Line',
        data: Daily.index.map(item => Object.values(item)[0]),
        fill: false,
        borderColor: '#d32f2f',
        tension: 0.1,
      },
    ],
  };
  const options = {
    layout: {
      padding: 20,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    maintainAspectRatio: false,

    plugins: {
      title: {
        display: true,
        text: 'Индекс спокойствия, по дням',
        padding: {
          // top: 20,
          bottom: 40,
        },
        font: {
          size: 16,
        },
      },
      datalabels: {
        align: 'end',
        anchor: 'end',
        offset: 15,
        display: true,
        font: {
          size: '18px',
          weight: 'bold',
        },
        color: 'black',
        formatter: function (value, context) {
          const foundDevelopmentsValue = developmentsValues.find(i => i === value);
          const foundDevelopmentsDate = developmentsDates.find(i => i === context.chart.data.labels[context.dataIndex]);
          if (foundDevelopmentsValue && foundDevelopmentsDate) {
            return value;
          } else {
            return '';
          }
        },
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        max: ' ',
        grid: {
          display: false,
        },
      },
      y: {
        max: maxValue,
        grid: {
          display: false,
        },
      },
    },
  };
  return (
    <div>
      <Line data={chartData} options={options} width={'500'} height={'200'} />
    </div>
  );
});
