import React from 'react';
import { Line } from 'react-chartjs-2';
const Weekly = require('../../../../assets/data/Weekly.json');

export const MultiLineChart = React.memo(props => {
  Weekly.news.map(item => Object.values(item)[0]);
  const olvCbuCprpChartData = {
    datasets: [
      { type: 'line', label: 'Новости', borderColor: '#d32f2f', data: Weekly.news.map(item => Object.values(item)[0]) },
      { type: 'line', label: 'Курс доллара', borderColor: '#0D2E44', data: Weekly.usd_rate.map(item => Object.values(item)[0]) },
    ],
    labels: Weekly.news.map(item => Object.keys(item)[0]),
  };

  const options = {
    layout: {
      padding: 20,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    maintainAspectRatio: false,

    plugins: {
      title: {
        display: true,
        text: 'Ключевые триггеры',
        padding: {
          top: 10,
          bottom: 30,
        },
        font: {
          size: 16,
        },
      },
      datalabels: {
        display: false,
      },

      legend: {
        display: true,
        position: 'bottom',
        align: 'centre',
        labels: {
          boxHeight: 0,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      y: {
        max: 100,
        position: 'left',
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div style={{ position: 'relative' }}>
      <Line width={'600'} height={'290'} data={olvCbuCprpChartData} options={options} />
    </div>
  );
});
