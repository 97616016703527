import React, { useState, useEffect } from 'react';
import './App.css';
import { LineChart } from '../src/ui/components/Charts/LineChart/LineChart';
import { LineChartDays } from '../src/ui/components/Charts/LineChartDays/LineChartDays';
import { MultiLineChart } from './ui/components/Charts/MultiLineChart/MultiLineChart';
import { RedButton } from './ui/components/RedButton/RedButton';
import { ExportPPT } from './ui/components/ExportPPT/ExportPPT';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowTurnUp } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import colorStar from '../src/assets/images/Picture1.png';
const Weekly = require('../src/assets/data/Weekly.json');
const Daily = require('../src/assets/data/Daily.json');

export const App = () => {
  const [currentDate, setCurrentDate] = useState(moment.utc(new Date()).format('DD.MM.YYYY'));
  console.log(setCurrentDate);
  const [mobileMode, setMobileMode] = useState(window.screen.width < 1300 ? true : false);
  const [isExportPPT, setIsExportPPT] = useState(false);
  const onClickExportPPT = () => {
    setIsExportPPT(true);
  };

  const lastDateData = Object.values(Daily.index[Daily.index.length - 1])[0];
  const lastDateValue = Object.keys(Daily.index[Daily.index.length - 1])[0];

  const onResizeScreen = () => {
    if (window.screen.width < 1300) {
      setMobileMode(true);
    } else if (window.screen.width > 1300) {
      setMobileMode(false);
    }
  };
  useEffect(() => {
    window.addEventListener('resize', function () {
      onResizeScreen();
    });
  }, []);
  return (
    <div className='App'>
      <div className='header'>
        <div className='delimiterWrapper'>
          <img src={colorStar} alt='redStarSmall' style={{ width: '100%' }} />
          <div className='dateNow'>{currentDate}</div>
        </div>
      </div>
      {mobileMode ? (
        <div className='container'>
          <div className='content'>
            <div className='infoTextBlock'>
              <div className='indexBlock'>
                <div>
                  ИНДЕКС СПОКОЙСТВИЯ НА {lastDateValue}: <span className='indexNumber redIndex '>{lastDateData}</span>
                </div>
                <div>
                  ИНДЕКС СПОКОЙСТВИЯ ЦЕЛЕВОЙ: <span className='indexNumber greenIndex'>17</span>
                </div>
              </div>

              <div className='textBlock'>
                <ul>
                  <li className='textli'>В тревожные времена люди начинают вбивать в поиск «Новости» и «Курс доллара». По этим индикаторам активности можно судить об «Индексе спокойствия» в обществе.</li>
                  <li className='textli'>24 февраля этот уровень достиг значения 200. В период начала карантина «Индекс спокойствия» был равен 83.</li>
                  <li className='textli'>Оптимальный уровень «Индекса спокойствия» – это цифра 17, что говорит о спокойном уровне восприятия событий в обществе.</li>
                  <li className='textli'>После начала коронакризиса потребовалось 9 недель на стабилизацию «Индекса спокойствия до оптимального для общества уровня.</li>
                </ul>
              </div>
              <div className='lineChartDaysWrapper'>
                <LineChartDays />
              </div>
            </div>
            <div className='chartsBlock'>
              <div className='lineChartWrapper'>
                <LineChart data={Weekly.index} />
                <div className='border'></div>
                <div className='arrow'>
                  <FontAwesomeIcon icon={faArrowTurnUp} size='2x' />
                </div>
              </div>
              <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                <MultiLineChart />
              </div>
            </div>
            <RedButton onClick={onClickExportPPT} title={'Выгрузить ppt'} />
          </div>
        </div>
      ) : (
        <div className='container'>
          <div className='content'>
            <div className='chartsBlock'>
              <div className='lineChartWrapper'>
                <LineChart data={Weekly.index} />
                <div className='border'></div>

                <div className='arrow'>
                  <FontAwesomeIcon icon={faArrowRight} size='2x' />
                </div>
              </div>
              <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                <MultiLineChart />
              </div>
            </div>
            <div className='infoTextBlock'>
              <div className='lineChartDaysWrapper'>
                <LineChartDays />
              </div>
              <div className='indexBlock'>
                <div>
                  ИНДЕКС СПОКОЙСТВИЯ НА {lastDateValue}: <span className='indexNumber redIndex '>{lastDateData}</span>
                </div>
                <div>
                  ИНДЕКС СПОКОЙСТВИЯ ЦЕЛЕВОЙ: <span className='indexNumber greenIndex'>17</span>
                </div>
              </div>

              <div className='textBlock'>
                <ul>
                  <li className='textli'>В тревожные времена люди начинают вбивать в поиск «Новости» и «Курс доллара». По этим индикаторам активности можно судить об «Индексе спокойствия» в обществе.</li>
                  <li className='textli'>24 февраля этот уровень достиг значения 200. </li>
                  <li className='textli'>Оптимальный уровень «Индекса спокойствия» – это цифра 17, что говорит о спокойном уровне восприятия событий в обществе.</li>
                  <li className='textli'>После начала коронакризиса потребовалось 9 недель на стабилизацию «Индекса спокойствия до оптимального для общества уровня.</li>
                </ul>
              </div>
              <RedButton onClick={onClickExportPPT} title={'Выгрузить ppt'} />
            </div>
          </div>
        </div>
      )}

      {isExportPPT && <ExportPPT currentDate={currentDate} />}
      {/* <MultiRange min={1646082000000} max={1647291600000} onChange={({ min, max }) => console.log(`min = ${min}, max = ${max}`)} /> */}
    </div>
  );
};
