import React from 'react';
import pptxgen from 'pptxgenjs';
import colorStar from '../../../assets/images/Picture1.png';
const Daily = require('../../../assets/data/Daily.json');
const Weekly = require('../../../assets/data/Weekly.json');

export const ExportPPT = React.memo(props => {
  const { currentDate } = props;
  let pptx = new pptxgen();
  pptx.layout = 'LAYOUT_WIDE';
  pptx.defineSlideMaster({
    title: 'MASTER_SLIDE',
    objects: [{ text: { text: currentDate, options: { x: '11%', y: '7%', w: 5.5, h: 0.75 } } }, { image: { y: '8%', w: '100%', h: '8%', data: colorStar } }],
  });
  let slide1 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
  slide1.addText(`Индекс спокойствия, по дням`, { x: '35%', y: 0.5, w: 9, h: 3.2, color: '808080', fontSize: 22, bold: true });
  let arrLineChartDaysData = [
    {
      name: 'lineChartDays',
      labels: Daily.index.map(item => Object.keys(item)[0]),
      values: Daily.index.map(item => Object.values(item)[0]),
    },
  ];

  let lineChartDays = {
    x: 1.5,
    y: 2.5,
    w: '80%',
    h: '50%',

    chartColors: ['#d32f2f'],
    catAxisLabelColor: '808080',
    valAxisLabelColor: '808080',
    lineDataSymbol: 'none',
    catGridLine: { style: 'none' },
    showLegend: false,
    valAxisMinVal: 0,
    valAxisMaxVal: 200,
  };

  slide1.addChart(pptx.charts.LINE, arrLineChartDaysData, lineChartDays);

  let slide2 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
  slide2.addText(`Индекс спокойствия, по неделям`, { x: '35%', y: 0.5, w: 9, h: 3.2, color: '808080', fontSize: 22, bold: true });
  let arrLineChartData = [
    {
      name: 'lineChart',
      labels: Weekly.index.map(item => Object.keys(item)[0]),
      values: Weekly.index.map(item => Object.values(item)[0]),
    },
  ];

  let lineChart = {
    x: 1.5,
    y: 2.5,
    w: '80%',
    h: '50%',
    chartColors: ['#d32f2f'],
    catAxisLabelColor: '808080',
    valAxisLabelColor: '808080',
    lineDataSymbol: 'none',
    catGridLine: { style: 'none' },
    dataBorder: { pt: 2, color: 'FFFFFF' },
    showLegend: false,
    valAxisMinVal: 0,
    valAxisMaxVal: 200,
  };

  slide2.addChart(pptx.charts.LINE, arrLineChartData, lineChart);

  let slide3 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
  slide3.addText(`Ключевые триггеры`, { x: '40%', y: 0.5, w: 9, h: 3.2, color: '808080', fontSize: 22, bold: true });
  let multiLineChartData = [
    {
      name: 'Новости',
      labels: Weekly.news.map(item => Object.keys(item)[0]),
      values: Weekly.news.map(item => Object.values(item)[0]),
    },
    {
      name: 'Курс доллара',
      labels: Weekly.news.map(item => Object.keys(item)[0]),
      values: Weekly.usd_rate.map(item => Object.values(item)[0]),
    },
  ];

  let multiChart = {
    x: 1.5,
    y: 2.5,
    w: '80%',
    h: '50%',

    chartColors: ['#d32f2f', '#0D2E44'],
    catAxisLabelColor: '808080',
    valAxisLabelColor: '808080',
    lineDataSymbol: 'none',
    catGridLine: { style: 'none' },
    // valGridLine: { style: 'none' },
    legendPos: 'b',
    legendFontSize: 16,
    legendColor: '808080',
    showLegend: true,
    valAxisMinVal: 0,
    valAxisMaxVal: 100,
  };

  slide3.addChart(pptx.charts.LINE, multiLineChartData, multiChart);
  pptx.writeFile({ fileName: 'charts.pptx' });
  return <div></div>;
});
